import { ReactNode, useState, useRef } from "react"
import { Head, Link } from "blitz"
import {
  chakra,
  Box,
  Container,
  Flex,
  Heading,
  IconButton,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react"
import { BiMoon, BiSun } from "react-icons/bi"
import Logo from "app/core/components/Logo"
import useClickOutside from "app/core/hooks/useClickOutside"

type LayoutProps = {
  title?: string
  noMenus?: boolean
  noHeader?: boolean
  children: ReactNode
}

const NavbarWrapper = chakra(Flex, {
  baseStyle: {
    minHeight: 75,
    pt: 3,
    pb: 5,
    position: "fixed",
    left: 0,
    right: 0,
    zIndex: 2,
  },
})

const ThemeSwitcher = () => {
  const ref = useRef<any>()
  const { colorMode, toggleColorMode } = useColorMode()
  const [themeSelectionIsOpen, openThemeSelection] = useState<boolean>(false)
  useClickOutside(ref, () => openThemeSelection(false))

  return (
    <Flex {...(themeSelectionIsOpen ? { ref } : {})} sx={{ flexDirection: "column" }}>
      <IconButton
        variant="ghost"
        aria-label={colorMode === "light" ? "Enable Dark mode" : "Enable Light mode"}
        fontSize="20px"
        icon={colorMode === "light" ? <BiMoon /> : <BiSun />}
        onClick={toggleColorMode}
      />
    </Flex>
  )
}

const Layout = ({ title, noMenus = false, noHeader = false, children }: LayoutProps) => {
  const bg = useColorModeValue(
    "linear-gradient(rgba(255, 255, 255), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.001))",
    "linear-gradient(rgba(18, 21, 21), rgba(18, 21, 21, 0.8), rgba(18, 21, 21, 0.001))"
  )

  return (
    <Box sx={{ position: "relative", height: "100vh" }}>
      <Head>
        <title>{title || "Budg"}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <NavbarWrapper sx={{ bg }}>
        <Container
          maxW="container.lg"
          sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
        >
          <Link href="/" passHref>
            <Flex as="a" href="/" sx={{ alignItems: "center" }}>
              <Logo />
              <Heading ml={2}>budg</Heading>
            </Flex>
          </Link>

          <Box>
            <ThemeSwitcher />
          </Box>
        </Container>
      </NavbarWrapper>

      {children}

      <style jsx global>{`
        body {
          margin: 0;
          font-family: Untitled Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
            "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
          -webkit-tap-highlight-color: transparent;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          line-height: 1;
        }

        svg {
          display: block;
          vertical-align: middle;
        }

        html,
        body,
        #root,
        #__next,
        #__next > div,
        .theme-0 {
          height: 100%;
        }

        #__next {
          position: relative;
          z-index: 0;
        }
      `}</style>
    </Box>
  )
}

export default Layout
