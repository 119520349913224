import React from "react"
import { BlitzPage } from "blitz"
import GuestLayout from "app/core/layouts/GuestLayout"
import { LoginForm } from "app/auth/components/LoginForm"
import { Flex } from "@chakra-ui/react"

const LoginPage: BlitzPage = () => {
  return (
    <Flex as="main" sx={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
      <LoginForm />
    </Flex>
  )
}

LoginPage.getLayout = (page) => <GuestLayout title="Log In">{page}</GuestLayout>

export default LoginPage
